import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserForm } from './components/UserForm';
import { LandingPage } from './components/LandingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<UserForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;