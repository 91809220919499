import React from 'react';
import { Link } from 'react-router-dom';
import { Activity, Search, Zap, ArrowRight } from 'lucide-react';

export const LandingPage = () => {
  const features = [
    {
      icon: <Activity className="w-6 h-6 text-blue-500" />,
      title: "Analyze",
      description: "Automatically aggregate and analyze metrics, crash dumps, and logs to identify incident patterns"
    },
    {
      icon: <Search className="w-6 h-6 text-blue-500" />,
      title: "Diagnose",
      description: "AI-powered analysis of stack traces, code changes, and deployment history"
    },
    {
      icon: <Zap className="w-6 h-6 text-blue-500" />,
      title: "Mitigate",
      description: "Get intelligent suggestions for rollbacks and runbook procedures"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50">
      {/* Navigation */}
      <nav className="border-b bg-white/80 backdrop-blur-sm fixed w-full z-10">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <span className="text-2xl font-bold text-blue-600">Connect the Dots</span>
          <Link 
            to="/signup"
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
          >
            Join Beta <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="container mx-auto px-6 pt-32 pb-20">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            LLM-Powered On-Call Management
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Transform your site reliability with AI that correlates, diagnoses, 
            and helps mitigate on-call incidents faster than ever.
          </p>
          <Link 
            to="/signup"
            className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-lg flex items-center gap-2 mx-auto w-fit"
          >
            Get Early Access <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </section>

      {/* Feature Grid */}
      <section className="container mx-auto px-6 py-20">
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <div className="mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Tool Integration Section */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Works With Your Stack</h2>
            <p className="text-xl text-gray-600 mb-8">
              Seamlessly integrates with Datadog, PagerDuty, and SauceLabs to enhance your existing monitoring setup
            </p>
            <div className="grid grid-cols-3 gap-6 max-w-lg mx-auto">
              {/* Replace with actual logos */}
              <div className="h-12 bg-gray-100 rounded-lg flex items-center justify-center">Datadog</div>
              <div className="h-12 bg-gray-100 rounded-lg flex items-center justify-center">PagerDuty</div>
              <div className="h-12 bg-gray-100 rounded-lg flex items-center justify-center">SauceLabs</div>
            </div>
          </div>
        </div>
      </section>

      {/* Built by Engineers Section */}
      <section className="container mx-auto px-6 py-20">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">Built by On-Call Engineers</h2>
          <p className="text-xl text-gray-600 mb-8">
            We've experienced the pain of manual incident response across different tech stacks, teams, and service types. 
            Now we're leveraging LLMs to automate these workflows.
          </p>
          <Link 
            to="/signup"
            className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-lg flex items-center gap-2 mx-auto w-fit"
          >
            Join the Waitlist <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </section>a

      {/* Final CTA */}
      <section className="bg-gray-900 text-white py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to transform your on-call experience?</h2>
            <p className="text-xl opacity-90 mb-8">
              Join other engineering teams who are using AI to respond to incidents faster and more effectively.
            </p>
            <Link 
              to="/signup"
              className="px-8 py-4 bg-white text-gray-900 rounded-lg hover:bg-gray-100 text-lg flex items-center gap-2 mx-auto w-fit"
            >
              Get Started <ArrowRight className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};