import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';

interface UserData {
  name: string;
  email: string;
  companyName: string;
}

export const UserForm: React.FC = () => {
  const [formData, setFormData] = useState<UserData>({
    name: '',
    email: '',
    companyName: '' 
  });
  
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (isSubmitted) {
    return (
      <Card className="w-full max-w-md mx-auto mt-8">
        <CardHeader>
          <h2 className="text-2xl font-bold text-center">Thank You!</h2>
        </CardHeader>
        <CardContent>
          <div className="text-center space-y-4">
            <p className="text-gray-600">
              Thanks for signing up! We'll be in touch soon with updates about your account and to personally welcome you to Connect Dot :)
            </p>
            <p className="text-gray-500 text-sm">
              Keep an eye on your inbox for more information.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form data:', formData);
    setIsSubmitted(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This function handles changes to any input field in the form
    const { name, value } = e.target;
    
    // Update the form data using the previous state
    setFormData(prev => ({
      // Spread the previous form data
      ...prev,
      // Update the field that changed
      // For company name, trim whitespace and capitalize first letter of each word
      [name]: name === 'companyName' 
        ? value.trim().replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        : value
    }));
  };

  return (
    <Card className="w-full max-w-md mx-auto mt-8">
      <CardHeader>
        <h2 className="text-2xl font-bold">User Information</h2>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium">
              Name
            </label>
            <Input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <Input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium">
             Company Name
            </label>
            <Input
              id="companyName"
              name="companyName"
              type="text"
              value={formData.companyName}
              onChange={handleChange}
              className="mt-1"
            />
          </div>

          <Button type="submit" className="w-full">
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};